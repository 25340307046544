import { ModeType, UserRole } from "helpers";
import { lazy } from "react";
import { Redirect } from "react-router-dom";

const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const RemittanceTab = lazy(() => import("pages/remittance-tab/RemittanceTab"));
const Performance = lazy(() => import("pages/performance/Performance"));
const PGAFormComponent = lazy(() => import("pages/pga-form/PGAFormComponent"));
const DocumentHub = lazy(() => import("pages/document-hub/DocumentHub"));
const MyProfile = lazy(() => import("pages/my-profile/MyProfile"));
const AlertList = lazy(() => import("pages/alert-list/AlertList"));
const PreferenceSetting = lazy(() => import("pages/preference-setting/PreferenceSetting"));
const ReportWeight = lazy(() => import("pages/report-weight/ReportWeight"));
const ReportCalendarOfSupply = lazy(
  () => import("pages/report-calendar-of-supply/ReportCalendarOfSupply")
);
const ReportDeadWeightGain = lazy(
  () => import("pages/report-dead-weight-gain/ReportDeadWeightGain")
);
const ReportPostMortem = lazy(() => import("pages/report-post-mortem/ReportPostMortem"));
const ReportSummary = lazy(() => import("pages/report-summary/ReportSummary"));
const ReportGrade = lazy(() => import("pages/report-grade/ReportGrade"));
const SupportForm = lazy(() => import("pages/support-form/SupportForm"));
const BlankPage = lazy(() => import("pages/blank/BlankPage"));
const FrequentlyAskedQuestions = lazy(
  () => import("pages/frequently-asked-questions/FrequentlyAskedQuestions")
);
const CookiesPolicy = lazy(() => import("pages/cookies-policy/CookiesPolicy"));
const FarmGroup = lazy(() => import("pages/farm-group/FarmGroup"));
const BenchmarkReport = lazy(() => import("pages/farm-group/BenchmarkReport"));

const routes: Array<{
  path: string;
  name?: string;
  component: any;
  title?: string;
  exact?: boolean;
  useAuthLayout?: boolean;
  isSettingMenu?: boolean;
  isSubModule?: boolean;
  isDisplaySwitchMode?: boolean;
  isDisplayModeHeader?: boolean;
  keepQueryParam?: boolean;
  breadcrumbs?: any[];
  modeTypes?: ModeType[];
  temporaryUsers?: UserRole[];
}> = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    title: "Dashboard",
    useAuthLayout: true,
    isDisplaySwitchMode: true,
  },
  {
    path: "/remittance/detail",
    name: "remittance-detail",
    component: RemittanceTab,
    title: "RemittanceDetail",
    useAuthLayout: true,
    isSubModule: true,
    isDisplaySwitchMode: true,
    breadcrumbs: [
      { title: "Back to Remittance list", path: "/remittance" },
      { title: "Lot Details" },
    ],
  },
  {
    path: "/remittance/custom-vat-summary",
    name: "Custom VAT Summary",
    component: RemittanceTab,
    title: "Custom VAT Summary",
    useAuthLayout: true,
    isSubModule: true,
    isDisplaySwitchMode: true,
    breadcrumbs: [{ title: "Custom VAT Summary" }],
  },
  {
    path: "/remittance",
    name: "remittance",
    component: RemittanceTab,
    title: "Remittance",
    useAuthLayout: true,
    isDisplaySwitchMode: true,
    breadcrumbs: [{ title: "Remittances" }],
  },
  {
    path: "/pga-form",
    name: "pga-form",
    component: PGAFormComponent,
    title: "PGAForm",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/document-hub",
    name: "document-hub",
    component: DocumentHub,
    title: "DocumentHub",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/frequently-asked-questions",
    name: "Frequently Asked Questions",
    component: FrequentlyAskedQuestions,
    title: "FrequentlyAskedQuestions",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/cookies-policy",
    name: "Cookies Policy",
    component: CookiesPolicy,
    title: "CookiesPolicy",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: MyProfile,
    title: "MyProfile",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/preference-setting",
    name: "preference-setting",
    component: PreferenceSetting,
    title: "PreferenceSetting",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/alert-list",
    name: "alert-list",
    component: AlertList,
    title: "AlertList",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/performance/report-weight",
    name: "report-weight",
    component: ReportWeight,
    title: "ReportWeight",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance/report-grade",
    name: "report-grade",
    component: ReportGrade,
    title: "ReportGrade",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance/report-supply",
    name: "report-supply",
    component: ReportCalendarOfSupply,
    title: "ReportCalendarOfSupply",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance/report-gain",
    name: "report-gain",
    component: ReportDeadWeightGain,
    title: "ReportDeadWeighGain",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance/report-mortem",
    name: "report-mortem",
    component: ReportPostMortem,
    title: "ReportPostMortem",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance/report-summary",
    name: "report-summary",
    component: ReportSummary,
    title: "ReportSummary",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    keepQueryParam: true,
    isDisplayModeHeader: true,
    breadcrumbs: [
      { title: "Back to Performance reports", path: "/performance" },
      { title: "View Report" },
    ],
  },
  {
    path: "/performance",
    name: "performance",
    component: Performance,
    title: "Performance",
    useAuthLayout: true,
    isDisplaySwitchMode: true,
    breadcrumbs: [{ title: "Performance" }],
  },
  {
    path: "/support-form",
    name: "support-form",
    component: SupportForm,
    title: "SupportForm",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/blank-page",
    name: "blank-page",
    component: BlankPage,
    title: "BlankPage",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/c/dashboard" />,
    useAuthLayout: true,
    isDisplaySwitchMode: false,
  },
  {
    path: "/farm-group/benchmark-report",
    name: "benchmark-report",
    component: BenchmarkReport,
    title: "Benchmark Report",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    isDisplayModeHeader: true,
    modeTypes: [ModeType.Cattle],
    breadcrumbs: [
      { title: "Back to Farm Groups list", path: "/farm-group" },
      { title: "Benchmark Report" },
    ],
    temporaryUsers: [UserRole.Admin, UserRole.Farmer, UserRole.AgricultureManager],
  },
  {
    path: "/farm-group",
    name: "farm-group",
    component: FarmGroup,
    title: "Farm Group",
    useAuthLayout: true,
    isDisplaySwitchMode: false,
    isDisplayModeHeader: true,
    modeTypes: [ModeType.Cattle],
    breadcrumbs: [{ title: "Farm Groups" }],
    temporaryUsers: [UserRole.Admin, UserRole.Farmer, UserRole.AgricultureManager],
  },
];
export { routes };
