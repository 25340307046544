export enum ProviderType {
  No = 0,
  Cattle = 1,
  Sheep = 2,
  Both = 3,
}

export enum ModeType {
  Cattle = "C",
  Sheep = "S",
}

export const Sites = {
  Perth: "PE",
  Ellesmere: "EL",
  Yetminster: "YE",
  Lurgan: "LU",
  BOA: "BA",
};

export const PAGE_DEFAULT = {
  pageSize: 10,
  pageSizeMax: 999,
  firstPage: "1",
};

export const PATH_MODE_VARIABLE = "mode";

export const DATE_TIME_FORMAT = "DD/MM/YYYY";
export const DATE_TIME_FORMAT_CALENDAR = "MM/DD/YYYY";

export const UK_TIMEZONE = "Europe/London";

export const FILTER_ALL = '[ "All" ]';

export enum NotificationTypes {
  UnreadAlert = "UnreadAlert",
  AlertToDocumentHub = "AlertToDocumentHub",
  ToggleMaintenanceStatus = "ToggleMaintenanceStatus",
  AgreementFormStatusChange = "AgreementFormStatusChange",
  FarmGroupStatusChange = "FarmGroupStatusChange",
  ResetPasswordNotification = "ResetPasswordNotification",
  PortalReport = "PortalReport",
  PgaFormReset = "PgaFormReset",
  LoginSuccessEvent = "LoginSuccessEvent",
}

export const NOT_AVAILABLE = "N/A";
export const AWAITING_DATA = "Awaiting Data";

export enum UserRole {
  Admin = "admin",
  Farmer = "farmer",
  Buyer = "buyer",
  AgricultureManager = "agriculturemanager",
}

export const NO_DATA = "Sorry, we couldn't find any data that matched your criteria.";

export const countriesPGAForm = [
  {
    label: "England",
    value: 1,
  },
  {
    label: "Scotland",
    value: 2,
  },
  {
    label: "Wales",
    value: 3,
  },
  {
    label: "Northern Ireland",
    value: 4,
  },
];
